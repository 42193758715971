import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Spinner, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import UpdateInvigilatorCountModal from '../../../../components/Modal/UpdateInvigilatorCount.modal';
import AdminService from '../../../../services/admin.service';
import { LoaderContext } from '../../../../Context';


const InvigilatorCenterTable = () => {

    let params = useParams()
    let navigate = useNavigate();

    let [invigilatorDets, setInvigilatorDets] = useState<any>([]);
    let [tableData, setTableData] = useState<any>([]);
    let [totalStudentCount, setTotalStudentCount] = useState<number>(0);
    let [showUpdateBox, setShowUpdateBox] = useState<boolean>(false)
    let [activeIndex, setActiveIndex] = useState(-1)
    let [updatedCount, setUpdatedCount] = useState(0)

    const {loading, setLoading}:any = useContext(LoaderContext);


    const getAssignedInviliatorCount = async () => {
        await AdminService.getAssignedInviliatorCount(params.examId, params.shiftId).then((res) => {
            let temp: any = [];
            setInvigilatorDets(res.data.invigilatorData);
            let noOfStudentsPerInvigilator = res.data.noOfStudentsPerInvigilator;
            let student_count = res.data.student_count;
            let unique_centers = res.data.unique_centers.centerPreference;
            setTotalStudentCount(student_count);
            for (let i of res.data.invigilatorData) {
                console.log(i.center);
                let obj_temp = {
                    center: i.center,
                    noOfInvigilator: i.noOfInvigilator,
                    noOfStudent: unique_centers[i.center],
                    studentInvigilatorRatio: (unique_centers[i.center] / parseInt(i.noOfInvigilator)).toFixed(2)
                };
                temp.push(obj_temp);
            }
            // temp.sort(compare);
            // console.log(temp)
            setTableData(temp);
        }
        )
    }

    const handleUpdate = (count: any) => {
        let arr: any = invigilatorDets;
        // console.log(invigilatorDets);
        arr[activeIndex].noOfInvigilator = count;
        setInvigilatorDets(arr);
        setActiveIndex(-1);
        setShowUpdateBox(false);
    }
    // updateInvigilatorsCounts
    const updateInvigilatorsCounts = async () => {
        let obj = {
            "invigilatorsRequired": invigilatorDets
        }
        await AdminService.updateInvigilatorsCounts(params.examId, params.shiftId, obj).then((res: any) => {
            if (res.status == 200) {
                toast.success("invigilator count update success");
                getAssignedInviliatorCount();
            }
            else {
                toast.error("Something went wrong");
            }
        }
        );
    }

    const confirmInvigilator = async () => {
        setLoading(true)
        await AdminService.confirmInvigilator(params.examId, params.shiftId, {}).then(res => {
            if (res.status == 200) {
                setLoading(false);
                toast.success("Invigilator confirmed successfully");
            }
        }).catch((err) => {
            console.log(err);
            setLoading(false)
            toast.error(err.response.data || "Something went wrong");
        })

    }

    useEffect(() => {
        getAssignedInviliatorCount();
    }, [])

    return (
        <>

            <Card className="bio-card mt-4">
                <Card.Body>
                    <h3 className='my-3'>List of invigilators assigned to a center</h3>
                    <Table striped hover>
                        <thead className='text-center'>
                            <tr>
                                <th><b>Center id</b></th>
                                <th><b>Number of Students</b></th>
                                <th><b>Invigilators Count</b></th>
                                <th><b>Student Invigilator Ratio</b></th>
                                <th><b>Updated <br />Invigilator Count,Student Invigilator Ratio</b></th>
                                <th><b>Action</b></th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {tableData.length > 0 && tableData.map((data: any, index: number) =>
                                <tr>
                                    <td className="text-monospace fw-bold">{data.center}</td>
                                    <td className='fs-6'>{data.noOfStudent}</td>
                                    <td className='text-primary'>{data.noOfInvigilator}</td>
                                    <td className='text-success'>{data.studentInvigilatorRatio}</td>
                                    <td className='text-secondary fw-bold'>{invigilatorDets[index].noOfInvigilator} , {(data.noOfStudent / invigilatorDets[index].noOfInvigilator).toFixed(2)}</td>
                                    <td><FontAwesomeIcon icon={faPencil} className="text-primary" onClick={() => {
                                        setShowUpdateBox(!showUpdateBox);
                                        setUpdatedCount(data.noOfInvigilator);
                                        setActiveIndex(index);
                                        return;
                                    }} /></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <div className='text-center text-muted fw-bold my-3'>
                Total No of students : {totalStudentCount}
            </div>
            <div className='text-center'>
                <Button variant='secondary'
                    onClick={updateInvigilatorsCounts}
                    size="sm"
                > Update table</Button>
                <Button variant="primary"
                    onClick={confirmInvigilator}
                    disabled={loading}
                    className="ms-2"
                    size="sm"

                > Confirm Invigilater Data {loading && <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> }</Button>
            </div>

            {showUpdateBox && <UpdateInvigilatorCountModal
                handleClose={() => setShowUpdateBox(false)}
                show={showUpdateBox}
                updatedCount={updatedCount}
                setUpdatedCount={setUpdatedCount}
                handleUpdate={handleUpdate}
            />

            }


            <div className='d-flex flex-row justify-content-between align-items-center'>
                <button
                    className='btn btn-success my-3 align-right'
                    onClick={(e: any) => navigate("/dashboard/createInvigilators/" + params.examId + "/" + params.shiftId)}
                >Back</button>
                <button
                    className='btn btn-success my-3 align-right'
                    onClick={(e: any) => navigate("/dashboard/invigilatordata/" + params.examId + "/" + params.shiftId)}
                >Next</button>
            </div>

        </>
    )
}

export default InvigilatorCenterTable