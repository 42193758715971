import React from "react";
import { LoaderContext } from "../../Context";

import './loader.css';
import loaderImg from './loader.svg';

import { Image } from "react-bootstrap";

export default function Loading() {
    const { loading, setLoading } = React.useContext<any>(LoaderContext);

    return (
        <>
            {loading && <div className="xcn-loader">
                <div>
                    <div className="d-flex justify-content-center align-items-center">
                        <Image src={loaderImg} alt="loader" height="35" />
                    </div>
                    <div className="d-flex justify-content-center align-items-center text-white h5 mt-3">
                        Loading...
                    </div>
                </div>
            </div>}
        </>
    )
}