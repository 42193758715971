import React from "react";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import { faHome, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import IRouter from "../../interfaces/IRouter.interface";
import Auth from "../../Auth";

interface ISideBar {
  menuData: IRouter[];
  panelName: string;
  baseUrl: string;
}

export default function SideBar({ menuData, panelName, baseUrl }: ISideBar) {
  let navigate = useNavigate();
  const location = useLocation();

  const showAllowedMenu = menuData.filter(
    (routes) => routes.navbarShow === true
  );

  const selectedRoute = (index: number, route: IRouter) => {
    return (
      <div
        key={index}
        className="xcn-sidebar-links active xcn-link-pointer text-primary d-flex align-items-center"
        onClick={() => navigate(baseUrl + "/" + route.path)}
      >
        <FontAwesomeIcon icon={route.activeIcon} />
        <div className="ms-2">{route.name}</div>
      </div>
    );
  };

  const notSelectedRoute = (index: number, route: IRouter) => {
    return (
      <div
        key={index}
        className="xcn-sidebar-links-inactive xcn-link-pointer d-flex align-items-center"
        onClick={() => navigate(baseUrl + "/" + route.path)}
      >
        <FontAwesomeIcon icon={route.activeIcon} />
        <div className="ms-2">{route.name}</div>
      </div>
    );
  };

  return (
    <>
      <Nav
        defaultActiveKey="0"
        className="flex-column xcn-dashboard-sub-nav flex-sm-column bg-primary"
      >
        <div className="d-flex justify-content-center align-items-center mb-1">
          <div className="text-white fw-bold mt-4 xcn-text-12">
            <span>
              <FontAwesomeIcon
                icon={faHome}
                className="me-2 xcn-link-pointer"
              />
            </span>
            EXMAUTH
          </div>
        </div>

        <hr className="text-white mx-3 mt-4" />

        {showAllowedMenu.map((data, index) => {
          console.log(data.path);
          return (
            <>
              {location.pathname.split("/")[2] === data.path
                ? selectedRoute(index, data)
                : notSelectedRoute(index, data)}
            </>
          );
        })}

        <div className="xrg-nav-bottom">
          <hr className="text-white mx-3" />

          <div className="fw-bold text-center"
          onClick={()=>Auth.clearAuthToken()}
          >
            <Link className="text-white xcn-text-10" 
            to="/logout">
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="text-white me-2"
              />
              Logout
            </Link>
          </div>
        </div>
      </Nav>
    </>
  );
}
