import { faCheck, faCheckCircle, faDownload, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Card } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import DropzoneData from '../../../../components/Dropzone/Dropzone';
import AdminService from '../../../../services/admin.service';
import ExamService from '../../../../services/exam.service';
import { LoaderContext } from '../../../../Context';
import CreateStudentModal from '../../../../components/Modal/CreateStudent.modal';

import { saveAs } from 'file-saver';

interface Item {
    // Define the type for your JSON items here
    id: number;
    name: string;
    // ... other properties
}

const UploadExamShiftCSV = () => {
    let params = useParams();
    let navigate = useNavigate();


    const [pastUploads, setPastUploads] = useState<any>()

    const { loading, setLoading }: any = useContext(LoaderContext);
    const [showCreateStudentModal, setShowCreateStudentModal] = useState<boolean>(false);
    const [stats, setStats] = useState<any>({})


    const handleUpload = async (files: any) => {
        setLoading(true)
        let fd = new FormData();
        fd.append("userData", files[0]);
        await AdminService.uploadCsvData(params.examId, params.shiftId, fd).then(res => {
            if (res.status === 200) {
                toast.success("File Upload Successfully");
                getPastUploadedData()
                setLoading(false)
            }
        }).catch(e => {
            setLoading(false)
        })
    }

    const convertToCSV = (data: any) => {
        const csv = [];
        const columns = Object.keys(data[0]);
        csv.push(columns.join(','));

        data.forEach((item: any) => {
            const row = columns.map(col => item[col]);
            csv.push(row.join(','));
        });

        return csv.join('\n');
    };

    const convertAndDownloadCSV = (data: any) => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv' });
        saveAs(blob, 'data.csv');
    };

    const getPastUploadedData = async () => {
        await AdminService.getPastUploads(params.examId, params.shiftId).then(res => {
            if (res.status === 200) {
                setPastUploads(res.data)
            }
        })
    }

    const getInstructors = async () => {
        await AdminService.getInstructorByExamShift(params.examId, params.shiftId).then(res => {
            if (res.status === 200) {
                convertAndDownloadCSV(res.data.data.inv);
            }
        }).catch(e => {
            console.log(e)
            toast.error("Invigilator not found")
        })
    }

    const getStats = async () => {
        await AdminService.getStatsByExamShift(params.examId, params.shiftId).then(res => {
            if (res.status === 200) {
                setStats(res.data.data)
            }
        }).catch(e => {
            console.log(e)
            toast.error("Invigilator not found")
        })
    }

    useEffect(() => {
        getPastUploadedData()
        getStats()
    }, [])


    return (
        <>
            <h5 className='text-primary'>Exam Shift Data<div className="text-muted xcn-text-10 xcn-fw-300">{"Shift Id: " + params.shiftId}</div></h5>
            <div className='d-flex justify-content-end mt-3 align-items-center'>
                <div className='me-2'>
                    <span className='xcn-text-12'>
                        Instructor Count:
                    </span>
                    <span className='xcn-text-12 fw-bold ms-1'>
                        {stats && stats.inv_count ? stats.inv_count : " - "}
                    </span>
                </div>
                <div className='me-2'>
                    <span className='xcn-text-12'>
                        Student Count:
                    </span>
                    <span className='xcn-text-12 fw-bold ms-1'>
                        {stats && stats.student_count ? stats.student_count : " - "}
                    </span>
                </div>
                <Button
                    variant='primary'
                    size="sm"
                    onClick={(e: any) => getInstructors()}
                    className='me-2'
                    disabled={pastUploads ? false : true}
                >
                    Download Instructors
                </Button>
                <Button
                    variant='primary'
                    size="sm"
                    onClick={(e: any) => setShowCreateStudentModal(true)}
                    disabled={pastUploads ? false : true}
                >Create Single Student </Button>
            </div>

            <Card className="bio-card mt-4">
                <Card.Body>
                    <div>
                        <h6 className="text-muted fw-bold">Step 1. Upload User Data</h6>
                        <span className="text-primary xcn-text-12 xcn-link-pointer" onClick={() => window.open("https://exmcen-static.s3.ap-south-1.amazonaws.com/nixauth-demoUpload.zip")}>Download Sample <FontAwesomeIcon icon={faDownload} /></span>
                        <>
                            {pastUploads ?
                                <>
                                    <div>
                                        <Alert variant='success' className='mt-3'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='fw-bold'>File Uploaded</div>
                                                <div className='text-success xcn-text-12 fw-bold'><FontAwesomeIcon icon={faCheckCircle} /> Already Uploaded</div>
                                            </div>
                                            <div className='mt-3 xcn-text-10'>
                                                <span>
                                                    <ol>
                                                        <li>If you want to change data please re-upload in by creating a new shift</li>
                                                        <li>Uploaded data will be used for the exam</li>
                                                        <li>Make sure you have mandatory fields like
                                                            <ul className='fw-bold'>
                                                                <li>
                                                                    Center Id
                                                                </li>
                                                                <li>
                                                                    Name
                                                                </li>
                                                                <li>
                                                                    Zone
                                                                </li>
                                                                <li>
                                                                    City
                                                                </li>
                                                                <li>
                                                                    State
                                                                </li>
                                                                <li>
                                                                    Roll No
                                                                </li>
                                                                <li>
                                                                    Aadhar No
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ol>
                                                </span>
                                            </div>
                                        </Alert>


                                    </div>
                                </>
                                :
                                <div>
                                    <div
                                        className='border border-lg p-5 d-flex flex-column align-items-center'
                                    >
                                        <DropzoneData
                                            handleFileUpload={handleUpload}
                                        />
                                    </div>
                                </div>
                            }
                            <div className='d-flex justify-content-end mt-3 align-items-center'>
                                <Button
                                    variant='success'
                                    size="sm"
                                    onClick={(e: any) => navigate("/dashboard/mapping/" + params.examId + "/" + params.shiftId)}
                                    disabled={pastUploads ? false : true}
                                >Next <FontAwesomeIcon icon={faArrowRight} /> </Button>
                            </div>
                        </>
                        <div>

                        </div>
                    </div >
                </Card.Body>
            </Card>
            <CreateStudentModal
                show={showCreateStudentModal}
                handleClose={() => setShowCreateStudentModal(false)}
                examId={params.examId}
                shiftId={params.shiftId}
            />

        </>

    )
}

export default UploadExamShiftCSV