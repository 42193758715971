import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/Navbar";
import { DateTimePicker } from "../DateTimePicker";
import "./navbar.css";
import Select from "react-select"
import { Button, Col, Row } from "react-bootstrap";


interface INavbar {
  data: any;
  onSelectHandler: any;
  onChangeValue: any;
  shiftData: any[];
  onChangeShiftValue: any;
  getExamId:any;
  getShiftId:any
}

export function CSRReportNavbar({
  data,
  onSelectHandler,
  onChangeValue,
  shiftData,
  onChangeShiftValue,
  getExamId,
  getShiftId,
}: INavbar) {
  return (
    <Navbar bg="light" expand="lg" className="navbarWrapper">
      <Container fluid>
        <Navbar.Brand href="#">EXMAUTH</Navbar.Brand>
        <div className="d-flex gap-4">
          <Select
            options={data}
            onChange={(e: any) => {
              onChangeValue(e, "value");
              getExamId(e.value);
            }}
            placeholder="Select Exam"
          />
          <Select
            options={shiftData}
            onChange={(e: any) => {
              onChangeShiftValue(e, "value");
              getShiftId(e.value);
            }}
            placeholder="Select Shift Id"
          />
        </div>
      </Container>
    </Navbar>
  );
}
