import { faCheck, faEllipsisVertical, faEye, faPencil, faPenToSquare, faRotate, faTrash, faUpload, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Dropdown, Form, Row, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../../../../components/helper/Date';
import EditSettingModel from '../../../../components/Modal/EditSetting.modal';
import ShiftModal from '../../../../components/Modal/Shift.model';
import ClientTable from '../../../../components/table/Client.table';
import AdminService from '../../../../services/admin.service';
import ExamService from '../../../../services/exam.service';
import ConfirmModal from '../../../../components/Modal/Confirm.modal';

const ExamById = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [exam, setExam] = useState<any>();
    const [enumsData, setEnumsData] = useState<any>();
    const [examSetting, setExamSetting] = useState<any>();
    const [showEditModal, setShowEditModal] = useState<any>(false);
    const [showShiftModal, setShowShiftModal] = useState<boolean>(false);
    const [clients, setClients] = useState<any>();
    const [showDeleteShift, setShowDeleteModal] = useState<any>(undefined);

    const [toggleExamOffline, setToggleExamOffline] = useState<boolean>(false)

    const [settingValue, setSettingValue] = useState<any>([
        {
            name: "",
            allowed: true,
            forceRequired: true
        }
    ]);


    const handleCheckedValue = (e: any, enum_type: string) => {
        const old_settingValue = settingValue;
        const rem_settings = old_settingValue.filter((setting: any) => setting.method != enum_type);
        let current_enum = old_settingValue.find((setting: any) => setting.method == enum_type);
        if (!current_enum) {
            current_enum = {
                method: enum_type,
                [e.target.name]: e.target.checked
            }
        }
        else {
            current_enum = { ...current_enum, [e.target.name]: e.target.checked }
        }
        setSettingValue([...rem_settings, current_enum]);

    }

    const updateSetting = async () => {
        await AdminService.updateSettings(params.examId, settingValue).then((res) => {
            if (res.status === 200) {
                toast.success("Exam setting update successfully")
            }
            setShowEditModal(false);
            getExamSetting();
        }).catch((err) => {
            toast.error("Unable to update exam settings..")
        })
    }

    const getAllExamById = async () => {
        await ExamService.getAllExamById(params.examId).then((res) => {
            if (res.status === 200) {
                setExam(res.data.data.exam);
                setToggleExamOffline(res.data.data.exam?.offlineMode || false)
            }
        }).catch((err) => {
            console.log(err);

        })
    }

    const getAllEnums = async () => {
        await AdminService.getAllEnumsForVerification().then((res) => {
            if (res.status === 200) {
                setEnumsData(res.data.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const getExamSetting = async () => {
        await AdminService.getExamSetting(params.examId).then((res) => {
            if (res.status === 200) {
                setExamSetting(res.data.data.settings);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const getAllClients = async () => {
        await AdminService.getAllClients(params.examId).then((res) => {
            if (res.status === 200) {
                console.log(res.data);
                setClients(res.data.data.client);
            }
        })
    }

    const handleCreateClient = async () => {
        await AdminService.createClient(params.examId).then((res) => {
            if (res.status === 200) {
                toast.success("Client created successfully");
                getAllClients();
            }
        }).catch(err => {
            toast.error(err)
        })
    }

    const deleteShift = async () => {
        await AdminService.deleteShift(params.examId, showDeleteShift).then((res) => {
            if (res.status == 200) {
                toast.success("Shift deleted successfully");
                getAllExamById();
                setShowDeleteModal(undefined)
            }
        }).catch(e => {
            toast.error("Unable to delete shift");
            console.error(e)
        })
    }

    const handleToggleExamOffline = async () => {
        await AdminService.toggleExamOffline(params.examId).then((res) => {
            if (res.status === 200) {
                // setToggleExamOffline(true);
                toast.success("Success")
                getAllExamById()
            }
        }).catch(err => {
            toast.error(err.response.data);
            setToggleExamOffline(false)
        })
    }




    useEffect(() => {
        getAllExamById();
        getAllEnums();
        getExamSetting();
        getAllClients();
    }, [])


    return (
        <>
            <Card className="bio-card mt-4">
                <Card.Body>
                    <div className="d-flex align-items-center justify-content-between">
                        <h6 className="text-muted fw-bold">Exam: {exam && exam.name}</h6>
                        <Form.Group className="d-flex align-items-center">
                            <Form.Label className="fw-bold text-muted">Offline</Form.Label>
                            <Form.Switch className="mb-2 ms-3" checked={toggleExamOffline} onClick={handleToggleExamOffline} />
                        </Form.Group>
                    </div>
                    <hr className="text-muted" />
                    <Row>
                        <Col md={3}>
                            <div className='mt-3'>
                                <div className="d-flex align-items-center">
                                    <span className="fw-bold xcn-text-14">Auth Settings</span>
                                    <FontAwesomeIcon icon={faPencil} className="text-muted ms-1 xcn-text-12 xcn-link-pointer" onClick={() => { setShowEditModal(true) }} />
                                </div>
                                <div>
                                    <Table striped hover className="xcn-text-12">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className="fw-bold text-muted">Auth Type</span>
                                                </th>

                                                <th>
                                                    <span className="fw-bold text-muted">Allowed</span>
                                                </th>

                                                <th>
                                                    <span className="fw-bold text-muted">Required</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {enumsData && enumsData.length > 0 && examSetting && examSetting.length > 0 ? enumsData.map((data: any) => {
                                                const enum_val = examSetting.find((setting: any) => setting.method == data);
                                                return (
                                                    <tr>
                                                        <td><span className="fw-bold">{data}</span></td>
                                                        <td>{enum_val && enum_val.allowed ? <FontAwesomeIcon icon={faCheck} color="green" /> : <FontAwesomeIcon icon={faXmark} color="red" />}</td>
                                                        <td>{enum_val && enum_val.forceRequired ? <FontAwesomeIcon icon={faCheck} color="green" /> : <FontAwesomeIcon icon={faXmark} color="red" />}</td>
                                                    </tr>
                                                )
                                            })
                                                : 'No Data'}
                                        </tbody>

                                    </Table>
                                </div>
                            </div>
                        </Col>
                        <Col md={9}>
                            <div style={{ width: "100%", paddingLeft: "30px", borderLeft: "1px solid #cccccc" }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <p style={{ fontSize: "18px", fontWeight: "bold", marginTop: "20px" }}>Shifts</p>
                                    <Button variant='outline-primary' className="fw-bold" size="sm" onClick={() => { setShowShiftModal(true) }} >
                                        Update/Add Shift
                                    </Button>
                                </div>

                                <div>
                                    <div>
                                        <Table striped hover className="xcn-text-12">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span className="fw-bold">Shift Name</span>
                                                    </th>

                                                    <th>
                                                        <span className="fw-bold">Start time</span>
                                                    </th>

                                                    <th>
                                                        <span className="fw-bold">End time</span>
                                                    </th>
                                                    <th>
                                                        <span className="fw-bold">Biometric Start time</span>
                                                    </th>
                                                    <th>
                                                        <span className="fw-bold">Biometric End time</span>
                                                    </th>
                                                    <th>
                                                        <span className="fw-bold">Action</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {exam && exam.shifts && exam.shifts.length > 0 ? exam.shifts.map((shift: any) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td className='xcn-text-10 fw-bold text-primary'>{shift && shift.shiftName}</td>
                                                                <td className='xcn-text-10 fw-bold text-secondary'>{formatDate(shift && shift.startTime)}</td>
                                                                <td className='xcn-text-10 fw-bold text-secondary'>{formatDate(shift && shift.endTime)}</td>
                                                                <td className='xcn-text-10 fw-bold text-secondary'>{formatDate(shift && shift.biometricStartTime)}</td>
                                                                <td className='xcn-text-10 fw-bold text-secondary'>{formatDate(shift && shift.biometriEndTime)}</td>
                                                                <td><Button variant='primary' className="xcn-text-12" size="sm" onClick={() => { navigate('/dashboard/upload/' + exam._id + "/" + (shift._id)) }} >
                                                                    <FontAwesomeIcon icon={faUpload} />
                                                                </Button>
                                                                    <Button variant='danger' className="xcn-text-12 ms-2" size="sm" onClick={() => { setShowDeleteModal(shift._id) }} >
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </Button></td>
                                                            </tr>
                                                        </>
                                                    )
                                                }) : "No table data"}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <hr className="text-muted" />
                                <Row>
                                    <Col md={6}>
                                        <div style={{ borderRight: "1px solid #cccccc", paddingRight: "20px" }}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <p style={{ fontSize: "18px", fontWeight: "bold" }} className="mt-2">Clients</p>
                                                <Button variant='outline-primary' className="fw-bold" size="sm" onClick={handleCreateClient} >
                                                    Create Client
                                                </Button>
                                            </div>
                                            <div>
                                                <ClientTable clients={clients} examId={params.examId} reload={getAllClients} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <EditSettingModel
                show={showEditModal}
                handleClose={() => setShowEditModal(false)}
                enumsData={enumsData}
                settings={examSetting}
                handleValue={handleCheckedValue}
                handleSubmission={updateSetting}
            />
            <ShiftModal
                params={params.examId}
                show={showShiftModal}
                handleClose={() => setShowShiftModal(false)}
                reload={getAllExamById}
            />
            <ConfirmModal
                titleText={"Confirm Shift Delete"}
                bodyText={"Are you sure you want to delete this shift?"}
                show={showDeleteShift ? true : false}
                onHide={() => setShowDeleteModal(undefined)}
                button={
                    [
                        {
                            label: <><FontAwesomeIcon icon={faXmarkCircle} /> Cancel</>,
                            action: () => setShowDeleteModal(undefined),
                            variant: "secondary"
                        },
                        {
                            label: <><FontAwesomeIcon icon={faTrash} /> Delete</>,
                            action: deleteShift,
                            variant: "danger"
                        }
                    ]
                }

            />
        </>
    )
}

export default ExamById