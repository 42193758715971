import { faEllipsisVertical, faRotate, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Dropdown, Table } from 'react-bootstrap'
import toast from 'react-hot-toast'
import AdminService from '../../services/admin.service'
import ResetPasswordModal from '../Modal/resetPassword.modal'
import CustomToggle from '../toggle/CustomToggle'

interface IClientTable {
    clients: any,
    examId: any,
    reload: any
}

const ClientTable = ({ clients, examId, reload }: IClientTable) => {

    const [showResetModal, setShowResetModal] = useState<any>(undefined);


    const handleResetPassword = async (name: any) => {
        await AdminService.resetClientPassword(examId, name).then(res => {
            if (res.status === 200) {
                setShowResetModal(res.data);
                reload();
            }
        }).catch(err => {
            toast.error(err)
        })
    }

    const handleDeleteClient = async (id: any) => {
        await AdminService.deleteClient(id).then(res => {
            if (res.status === 200) {
                reload();
                toast.success("Client deleted successfully");
            }
        }).catch(err => {
            toast.error(err)
        })
    }


    return (
        <>
            <Table striped hover className="xcn-text-12">
                <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>
                            <span className="fw-bold">Userame</span>
                        </th>
                        <th>
                            <span className="fw-bold">Action</span>
                        </th>
                    </tr>
                </thead>
                {clients && clients.length > 0 ? clients.map((client: any, index: number) => {
                    return (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{client.username}</td>
                            <td>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        <FontAwesomeIcon icon={faEllipsisVertical} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleResetPassword(client.username)}>
                                            <FontAwesomeIcon icon={faRotate} className="text-primary me-2" />
                                            <span className="fw-bold">
                                                Reset Password
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDeleteClient(client._id)}>
                                            <FontAwesomeIcon icon={faTrash} className="text-danger me-2" />
                                            <span className="fw-bold">
                                                Delete
                                            </span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    )
                }) : "No clients found"}
                <tbody>
                </tbody>
            </Table>
            <ResetPasswordModal
                show={showResetModal}
                handleClose={() => setShowResetModal(false)}
            />
        </>

    )
}

export default ClientTable