import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  FloatingLabel,
  Form,
  Button,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

import PublicService from '../../../services/public.service';


const Login = () => {

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const navigate = useNavigate();

  const adminLogin = () => {
    PublicService.userLogin(email, password).then(res => {
      if (res.status === 200) {
        toast.success("Login Success")
        sessionStorage.setItem("authkey", res.data.data.token);
        sessionStorage.setItem("email", res.data.data.admin);
        navigate('/dashboard/statistics');
      }
    }).catch(e => {
      console.error(e)
      toast.error("Failed To login")
    })
  }

  useEffect(()=>{
    if(sessionStorage.getItem("authkey")){
      navigate("/dashboard/statistics")
    }
  },[])


  return (
    <>
      <Container>
        <h1 className="text-center font-weight-bold xcn-login-header">
          NixAuth - Admin Login
        </h1>
        <Row className="justify-content-md-center  mt-4">
          <Col xs lg="2" />
          <Col md="auto">
            <Card
              className="p-3"
              style={{ maxWidth: "25rem", minWidth: "25vw" }}
            >
              <>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email address"
                  className={"mb-3"}
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    name="email"
                    onChange={(e: any) => setEmail(e.target.value)}
                  />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Password">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={(e: any) => setPassword(e.target.value)}
                  />
                </FloatingLabel>
              </>
              <Button
                variant="primary"
                type="submit"
                className="mt-4"
                size="lg"
                onClick={adminLogin}
              >
                <span className="xcn-text-14">Login</span>
              </Button>
            </Card>
          </Col>
          <Col xs lg="2" />
        </Row>
      </Container>
    </>
  );
};

export default Login;
