import React from "react";
import CSRReportComponent from "../../../components/CSRReport";

const CSRReport = () => {
  return (
    <div>
      <CSRReportComponent />
    </div>
  );
};

export default CSRReport;
