import React from 'react';
import AllExam from '../Dashboard/Exam/AllExam';


export default function ExamIndex() {
    return (
        <>
            <AllExam />
        </>
    )
}