import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import CreateExamModel from '../../../../components/Modal/CreateExam.modal';
import ExamService from '../../../../services/exam.service';
import ExamCard from './ExamCard';


const AllExam = () => {

    const [examData, setExamData] = useState<any>([]);
    const [showExamModal, setShowExamModal] = useState<boolean>(false);

    const getAllExams = async () => {
        await ExamService.getAllExam().then((res) => {
            if (res.status === 200) {
                console.log(res.data.data)
                setExamData(res.data.data.exams);
            }
        }).catch(err => {
            console.log(err);

        })
    }



    useEffect(() => {
        getAllExams();
    }, [])

    return (
        <>
            <div className="d-flex justify-content-between text-primary">
                <h5>Exams<div className="text-muted xcn-text-10 xcn-fw-300">All Exams</div></h5>
                <div>
                    <Button onClick={() => setShowExamModal(true)} size="sm" >Create Exam</Button>
                </div>
            </div>
            {examData.length > 0 ? examData.map((exam: any) => {
                return (
                    <ExamCard
                        key={exam.examId}
                        data={exam}
                        reload={getAllExams}
                    />
                )
            }) : "No Exam Found"}
            <CreateExamModel
                show={showExamModal}
                handleClose={() => setShowExamModal(false)}
                reload={getAllExams}
            />
        </>
    )
}

export default AllExam