import { Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

interface ICSRReportCard {
  title: any;
  url: any;
  centerId: any
}


export function CSRReportCard(props: ICSRReportCard) {
  return (
    <Card className="shadow p-3 mb-5 bg-white rounded" style={{ width: "22rem" }}>
      <Card.Title className="xcn-text-12">{"Center Id: "+ props.centerId}</Card.Title>
      <div className="ms-5" style={{ width: "15rem" }}>
        <a className="btn btn-primary" href={props.url} target="_blank" rel="noreferrer" download> Download Report</a>
      </div>
      <div>
      </div>
    </Card>
  );
}
