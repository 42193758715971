import React from 'react'
import { Badge, Card, Table, Image } from 'react-bootstrap';
import { VerificationProcessEnum } from '../../enum/VerficationProcess.enum';
import { FontBold } from '../../components/table/TableText.formats'
import { FaCheck, FaCross } from 'react-icons/fa';


interface ICsrReportTable {
  columns: any[];
  data: any[];
}

const CsrReportTable = ({ columns, data }: ICsrReportTable) => {
  return (
    <>
      <Card className="shadow p-3 mb-3 mt-5 bg-white rounded">
        <Card.Body>
        <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Inv. Username</th>
                <th>Inv. Email</th>
                <th>S. Name</th>
                <th>Roll No.</th>
                <th>Photo</th>
                <th>Gender</th>
                <th>
                  Live Photo
                </th>
                <th
                  colSpan={Object.keys(VerificationProcessEnum).length}
                >
                  Verification
                </th>
              </tr>

              <tr className="text-muted fs-12">
                <th colSpan={8}></th>
                <th>
                  Fingerprint
                </th>
                <th>
                  Aadhar Iris
                </th>
                <th>
                  Aadhar Finger
                </th>
              </tr>
            </thead>
            <tbody>
              {data && data?.length > 0
                ? data?.map((data: any, index: any) => {
                  const verificationDetails = data?.verificationDetails;
                  const aadharIris = verificationDetails.find((ver: any) => ver.method == "aadhar-iris")
                  const fingerprint = verificationDetails.find((ver: any) => ver.method == "fingerprint")
                  const aadharFinger = verificationDetails.find((ver: any) => ver.method == "aadhar-finger")
                  
                  return (
                    <tr key={data?._id}>
                      <td>
                        <span className="fs-12 fw-bold">{index + 1}</span>
                      </td>
                      <td>
                        <span className="fs-italic fs-12">
                          {data?.invigilatorId?.userName}
                        </span>
                        
                      </td>
                      <td>
                        <span className="fs-12 cursor-pointer">
                          {data?.invigilatorId?.emailId}
                        </span>
                      </td>
                      <td>
                        <span className="fs-12 cursor-pointer">
                         {data?.studentId?.name}
                        </span>
                      </td>
                      <td>
                        <span className="fs-12 cursor-pointer">
                         {data?.studentId?.rollNo}
                        </span>
                      </td>
                     
                      <td>
                        <span className="fs-12 cursor-pointer">
                         <Image  src={data?.studentId?.avatar} height="40" width="40"/>
                        </span>
                      </td>
                      <td>
                        <span className="fs-12 cursor-pointer">
                         {data?.studentId?.gender}
                        </span>
                      </td>
                      <td>
                        <span className="fs-12 cursor-pointer">
                         <Image  src={data?.userLivePhoto} height="40" width="40"/>
                        </span>
                      </td>
                      <td>
                        <span className="fs-12 cursor-pointer">
                          {fingerprint && fingerprint?.manuallyVerified == true ? (
                            <Badge bg="warning">
                              Manually Verified
                            </Badge>
                          ) : fingerprint && fingerprint?.success == true ? (
                            <>
                              <Badge bg="success">
                                Verified
                              </Badge>
                            </>
                            ) : (
                              <Badge bg="danger">
                                Not Verified
                              </Badge>
                            )
                            }
                        </span>
                      </td>
                      <td>
                        <span className="fs-12 cursor-pointer">
                        {aadharIris && aadharIris?.manuallyVerified == true ? (
                            <Badge bg="warning">
                              Manually Verified
                            </Badge>
                          ) : aadharIris && aadharIris?.success == true ? (
                            <>
                              <Badge bg="success">
                                Verified
                              </Badge>
                            </>
                            ) : (
                              <Badge bg="danger">
                                Not Verified
                              </Badge>
                            )
                            }
                        </span>
                      </td>
                      <td>
                        <span className="fs-12 cursor-pointer">
                        {aadharFinger && aadharFinger?.manuallyVerified == true ? (
                            <Badge bg="warning">
                              Manually Verified
                            </Badge>
                          ) : aadharFinger && aadharFinger?.success == true ? (
                            <>
                              <Badge bg="success">
                                Verified
                              </Badge>
                            </>
                            ) : (
                              <Badge bg="danger">
                                Not Verified
                              </Badge>
                            )
                            }
                        </span>
                      </td>
                    </tr>
                  );
                })
                : "No data found"}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default CsrReportTable



