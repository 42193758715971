import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";
import makeUploadRequest from "../api/make.upload";

export default class ExamService {
    static async createExam(name: any) {
        return await makeRequest(urls.exam.createExam, "post", name);
    }

    static async getAllExam() {
        return await makeRequest(urls.exam.allExams, "get");
    }

    static async getAllExamById(examId: any) {
        return await makeRequest(urls.exam.getAllExamById + "/" + examId, "get");
    }

    static async addExamShift(examId: any, name: any, startTime: any, endTime: any) {
        return await makeRequest(urls.exam.addexamShiifts + "/" + examId, "post", {
            "shiftName": name,
            "startTime": startTime,
            "endTime": endTime
        })
    }
    static async toggleExamIsLive(examId: any){
        return await makeRequest(urls.exam.toggleExamIsLive+"/"+examId, "put");
    }

 

    static async updateShifts(examId: any, shifts:any) {
        return await makeRequest(urls.exam.updateShifts + "/" + examId, "put", shifts)
    }

    static async getExamShifts(examId: any){
        return await makeRequest(urls.admin.getExamShifts + "/" + examId, "get");
    }

    static async deleteExam(examId:any){
        return await makeRequest(urls.exam.deleteExam + "/" + examId, "delete");
    }
}
