import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { valueContainerCSS } from 'react-select/dist/declarations/src/components/containers';
import AdminService from '../../../../services/admin.service';

const MappingColumnIds = () => {

    let navigate = useNavigate();
    let params = useParams();
    let [internalIds, setInternalIds] = useState<any>([]);
    let [sheetIds, setsheetIds] = useState<any>([]);
    let [mapping, setMapping] = useState<any>([]);
    let [isMapping, setIsMapping] = useState<boolean>(false);

    // const updateMappingData = async () => {
    //     let obj = {
    //         "mappingData": mapping
    //     }
    //     await AdminService.updateMappingData(params.examId, params.shiftId, obj).then((res) => {
    //         if (res.status === 200) {
    //             toast.success("Mapping Updated Success");
    //             navigate("/createInvigilators/" + params.examId + "/" + params.shiftId)

    //         }
    //         else {
    //             toast.error("Try Again");
    //         }
    //     }).catch(err => toast.error("Something went wrong"));
    // }

    const uploadMapping = async (e: any) => {
        let obj = {
            "mapping": mapping
        }
        await AdminService.mapInterIdsWithSheetIds(params.examId, params.shiftId, obj).then((res) => {
            if (res.status === 200) {
                toast.dismiss();
                toast.success(res.data.message);
            }
            else {
                toast.dismiss();
                toast.error("Try Again");
            }
        }).catch(err => toast.error(err.response.data.message));
    }


    const getInternalId = async () => {
        await AdminService.getInternalId().then((res: any) => setInternalIds(res.data));
    }

    const getColumnData = async () => {
        await AdminService.getColumnData(params.examId, params.shiftId).then((res: any) => {
            setsheetIds(res.data.data.headers);
            let mappingArr: any = [];
            for (let i of res.data.data.headers) {
                mappingArr.push({
                    "sheet_id": i,
                    "internal_id": ""
                });
            }
            setMapping(mappingArr);
        })
        await AdminService.getMappings(params.examId, params.shiftId).then((res: any) => {
            console.log(res);
            if (!res.data.data.mappings.mappingData) {
                return;
            }
            else {
                // console.log(res);
                let originalMapping = res.data.data.mappings.mappingData;
                setIsMapping(true);
                setMapping(originalMapping);
            }
        })
    };

    const checkIfFieldsExist = async() => {
        await AdminService.checkIfFieldsExist(params.examId, params.shiftId).then((res: any) => {
            if(res.status == 200){
                navigate("/dashboard/createInvigilators/" + params.examId + "/" + params.shiftId)
            }
        }).catch(e => {
            console.log(e);
            toast.error(e.response.data.message);
        })
    }


    useEffect(() => {
        getColumnData();
        getInternalId();
    }, [])

    function handleMapping(index: any, val: String) {
        const old_mapping = mapping;
        const other_mapping = old_mapping.filter((data: any) => data.sheet_id != index)
        const newMapping = [...other_mapping, { sheet_id: index, internal_id: val }]
        setMapping(newMapping);
    }

    return (
        <>
            <h5 className='text-primary'>Exam Shift Data<div className="text-muted xcn-text-10 xcn-fw-300">{"Shift Id: " + params.shiftId}</div></h5>
            <Card className="bio-card mt-4">
                <Card.Body>
                    <div>
                        <h6 className="text-muted fw-bold">Step 2. User Data Mapping</h6>
                        <table className="table mt-3">
                            <thead>
                                <tr>
                                    <th>Sheet Name</th>
                                    <th>Internal Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sheetIds.length > 0 && sheetIds.map((sheetid: any, idx: any) => {
                                    let current_db_info = ""
                                    if (mapping) {
                                        const value = mapping && mapping.find((data: any) => data.sheet_id == sheetid)
                                        current_db_info = value?.internal_id
                                    }
                                    return (

                                        <tr key={sheetid}>
                                            <td>
                                                <i className='text-primary'>{sheetid}</i>
                                                <div className='my-1 text-muted xcn-text-12 text-primary'> Mapped With: <span className='fw-bold'>{current_db_info}</span></div>
                                            </td>
                                            <td>
                                                <select className="form-control"
                                                    onChange={(e) => handleMapping(sheetid, e.target.value)}
                                                >
                                                    <option value=''>Select</option>
                                                    {internalIds && internalIds?.map((id: any) => {
                                                        return <option
                                                            key={id} value={id}>{id}</option>
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className='d-flex justify-content-between align-items-center mt-3'>
                        <Button
                            onClick={(e: any) => navigate("/dashboard/upload/" + params.examId + "/" + params.shiftId)}
                            size="sm"
                            variant="secondary"

                        > <FontAwesomeIcon icon={faArrowLeft} /> Back</Button>
                        {/* <Button
                            className='btn btn-success my-3 align-right'
                        >Next & Create Mapping</Button> */}
                        {/* <div className="my-3">{message}</div> */}
                        <Button
                            onClick={uploadMapping}
                            variant="warning"
                            size="sm"
                        > Create Mapping </Button>
                        <Button variant='success' size="sm" onClick={checkIfFieldsExist}>
                            Next <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default MappingColumnIds

