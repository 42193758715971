import axios, { Method } from 'axios';

// export interface IParams { 
//     value: any, 
//     index: string
// }


export default async function makeRequest(url: any, method: any, inputPayload?: any) {
    let requestConfig = {
        baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_VERSION}`,
        url: url,
        method: method,
        headers: {
            Authorization: sessionStorage.getItem("authkey") || "",
        },
        data: {}
    };

    if (method !== 'get' && inputPayload) {
        requestConfig.data = inputPayload;
    }

    try {
        let response = await axios.request(requestConfig);
        return response;
    } catch (error) {
        throw error;
    }

}

export function makeParams(params: any) {
    let paramString = "?"
    for (const param in params) {
        if (params[param].value) {
            if (Number(param) != 0) paramString = paramString + "&"
            paramString = paramString + params[param].index + "=" + params[param].value
        }
    }
    return paramString;
}