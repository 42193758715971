import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";

export default class PublicService {
    static async userLogin(username: any, password: any) {
        return makeRequest(urls.public.userLogin, "post", {
            userName: username,
            password: password
        });
    }
}