import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'

interface IVerifiedUserModal {
    show: any,
    handleClose: any
    modalData?: any
}

const VerifiedUserModal = (props: any) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Verifications
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.modalData && props.modalData.verificationDetails.map((data: any) => {
                    return (
                        <>
                            <div className='ms-3 mt-3 mb-2'>
                                <h5>{data && data.method}</h5>
                                <span>Manually Verified: -</span><span className='ms-2'>{data && data.manuallyVerified === true ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faXmark} className="text-danger" />}</span> <br />
                                <span>Success:- </span><span className="ms-2">{data && data.success === true ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faXmark} className="text-danger" />}</span>
                            </div>
                            <hr />
                        </>
                    )
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default VerifiedUserModal