import React from 'react'
import { Container, Navbar } from 'react-bootstrap';
import Select from "react-select"
import Filter from './Filter';

const FiltersIndex = () => {
    return (
        <Filter />
    )
}

export default FiltersIndex