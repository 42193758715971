import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import toast from 'react-hot-toast'
import ExamService from '../../services/exam.service'
import moment from 'moment'


interface IShiftModal {
    show: any
    handleClose: any
    params: any
    reload: any
}

const ShiftModal = ({ show, handleClose, params, reload }: IShiftModal) => {



    const [shifts, setShifts] = useState<any>([]);


    const handleUpdate = async () => {
        await ExamService.updateShifts(params, shifts).then((res) => {
            if (res.status === 200) {
                toast.success("Shift created successfully");
                handleClose(false);
                reload();
            }
        }).catch((err) => {
            console.error(err);
            toast.error(JSON.stringify(err));
        })
    }

    const getExamShifts = async () => {
        await ExamService.getExamShifts(params).then(res => {
            if (res.status === 200) {
                setShifts(res.data.data.shifts.map((shift: any) => {
                    return {
                        ...shift,
                        startTime: new Date(shift.startTime),
                        endTime: new Date(shift.endTime),
                        biometricStartTime: new Date(shift.biometricStartTime),
                        biometriEndTime: new Date(shift.biometriEndTime),
                    }
                }));
            }
        })
    }

    const addShift = () => {
        setShifts([...shifts, {
            shiftName: "",
            startTime: new Date(),
            endTime: new Date(),
            biometricStartTime: new Date(),
            biometriEndTime: new Date(),
        }])
    }


    const deleteShift = () => {
        let curr_shifts: any[] = shifts;
        let len: number = curr_shifts.length;
        if (len > 0) setShifts(curr_shifts.slice(0, len - 1));
    }

    useEffect(() => {
        getExamShifts();
    }, [])

    const handleChangeData = (e: any, index: number) => {
        setShifts(shifts.map((data: any, i: number) => {
            if (i === index) {
                data[e.target.name] = e.target.value;
            }
            return data;
        }))
    }

    return (
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <Card>
                <Card.Body>
                    <h6 className="text-muted fw-bold">Shifts</h6>
                    <Alert variant='warning'> Data would be lost if a shift is deleted!! </Alert>
                    <div className='d-flex flex-row-reverse align-items-center mb-4'>
                        <Button className="ms-3" size="sm" onClick={() => deleteShift()} variant="danger">Remove</Button>
                        <Button
                            size="sm"
                            onClick={() => {
                                addShift()
                            }} >Add</Button>
                    </div>
                    {shifts && shifts.map((data: any, index: number) => {
                        return (
                            <Card className='bio-card mt-3'>
                                <Card.Body>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <label className='fw-bold xcn-text-12 text-secondary'>Shift Name</label>
                                        <Form.Control
                                            value={data.shiftName}
                                            className='mt-2 xcn-text-12'
                                            type="text"
                                            placeholder="Shift Name"
                                            name="shiftName"
                                            onChange={(e) => { handleChangeData(e, index) }}
                                        />
                                        <Row className="mt-2">
                                            <Col md>
                                                <label className='fw-bold xcn-text-12 text-secondary'>Start Time</label>
                                                <DatePicker
                                                    dateFormat="yyyy/MM/dd h:mm aa"
                                                    showTimeSelect
                                                    selected={data.startTime}
                                                    name="startTime"
                                                    onChange={(e) => { handleChangeData({ target: { name: "startTime", value: e } }, index) }}
                                                    className="form-control xcn-text-12"
                                                    minDate={new Date()}
                                                />
                                            </Col>
                                            <Col md>
                                                <label className='fw-bold xcn-text-12 text-secondary'>End Time</label>
                                                <DatePicker
                                                    dateFormat="yyyy/MM/dd h:mm aa"
                                                    showTimeSelect
                                                    name="endTime"
                                                    selected={data.endTime || moment().toDate()}
                                                    onChange={(e) => { handleChangeData({ target: { name: "endTime", value: e } }, index) }}
                                                    className="form-control xcn-text-12"
                                                    minDate={new Date()}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md>
                                                <label className='fw-bold xcn-text-12 text-secondary'>Biometric Start Time</label>
                                                <DatePicker
                                                    dateFormat="yyyy/MM/dd h:mm aa"
                                                    showTimeSelect
                                                    selected={data.biometricStartTime}
                                                    name="biometricStartTime"
                                                    onChange={(date: Date) => { handleChangeData({ target: { name: "biometricStartTime", value: date } }, index) }}
                                                    className="form-control xcn-text-12"
                                                    minDate={new Date()}
                                                />
                                            </Col>
                                            <Col md>
                                                <label className='fw-bold xcn-text-12 text-secondary'>Biometric End Time</label>
                                                <DatePicker
                                                    dateFormat="yyyy/MM/dd h:mm aa"
                                                    showTimeSelect
                                                    name="biometriEndTime"
                                                    selected={data.biometriEndTime}
                                                    onChange={(date: Date) => { handleChangeData({ target: { name: "biometriEndTime", value: date } }, index) }}
                                                    className="form-control xcn-text-12"
                                                    minDate={new Date()}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        )
                    }
                    )}

                    <Button className='mt-1' size="sm" onClick={handleUpdate}>Update</Button>
                </Card.Body>
            </Card>
        </Modal>
    )
}

export default ShiftModal