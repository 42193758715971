import React, { useEffect, useState } from "react";
import {
  CSRReportCard,
  LiveExamCard,
  StatisticsCard,
  StatsForCenterAuthCard,
  StatsForCenterFailure,
  StatsForRegisteredDevice,
  StatsForRegisteredUsers,
  UsersCard,
} from "../../../components/Card/Statistics.card";
import { FaBookOpen } from "react-icons/fa";
import SyncData from "./SyncData/SyncData";
import RegistrationStatusTable from "../../../components/table/RegistrationStatusTable";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag, faGear, faUser } from "@fortawesome/free-solid-svg-icons";
import StatsService from "../../../services/stats.service";

export default function StatisticsIndex() {

  const [userOnlineCount, setUserOnlineCount] = useState<any>(0);
  const [examCount, setExamCount] = useState<any>(0);
  const [csrCount, setCsrCount] = useState<any>(0);
  const [liveExamCount, setLiveExamCount] = useState<any>(0);


  const authUsersOnline = async () => {
    await StatsService.getAuthUsersOnline().then((res) => {
      if (res.status === 200) {
        setUserOnlineCount(res.data.data)
      }
    }).catch(err => {
      console.log(err)
    })
  }


  const totalLiveExamsCount = async () => {
    await StatsService.totalLiveExamsCount().then((res) => {
      if (res.status === 200) {
        setLiveExamCount(res.data.data)
        console.log(res.data, "auth");
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const totalExamCount = async () => {
    await StatsService.totalExamCount().then((res) => {
      if (res.status === 200) {
        setExamCount(res.data.data)
        console.log(res.data, "user");
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const totalCSRCount = async () => {
    await StatsService.totalCSRCount().then((res) => {
      if (res.status === 200) {
        setCsrCount(res.data.data)
        console.log(res.data, "online");
      }
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    authUsersOnline();
    totalLiveExamsCount();
    totalExamCount();
    totalCSRCount();
  }, [])




  return (
    <>
      <Row>
        <Col md={4}>
          <StatisticsCard
            // reactIcon={<FaBookOpen className="fs-1 text-muted" />}
            reactIcon={<FaBookOpen className="fs-1 text-muted" />}

            header="Exams"
            footer="Last Refreshed At"
            statsValue={<>{examCount && examCount.examCount}</>}
            change={{
              value: "0%",
              isNegative: true,
            }}
          />
        </Col> <Col md={4}>
          <CSRReportCard
            reactIcon={<FontAwesomeIcon icon={faFlag} className="fs-1 text-muted" />}
            // reactIcon={<FaBookOpen className="fs-1 text-muted" />}
            header="Center Report"
            footer="Last Refreshed At"
            statsValue={<>{csrCount && csrCount.csrCount}</>}
            change={{
              value: "0%",
              isNegative: false,
            }}
          />
        </Col>

        <Col md={4}>
          <LiveExamCard
            reactIcon={<FontAwesomeIcon icon={faGear} className="fs-1 text-muted" />}
            header="Live Exams"
            footer="Last Refreshed At"
            statsValue={<>{liveExamCount && liveExamCount.liveExamCount}</>}
            change={{
              value: "0%",
              isNegative: true,
            }}
          />
        </Col>
        </Row>
        <Row className="mt-3">
        <Col md={4}>
          <UsersCard
            reactIcon={<FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />}
            header="Users"
            footer="Last Refreshed At"
            statsValue={<>{userOnlineCount?.usersOnline?.length}</>}
            change={{
              value: "0%",
              isNegative: true,
            }}
          />
        </Col>
      </Row>
      {/* <StatsForCenterAuthCard centerId={""} authData={2000} /> */}
      {/* <StatsForCenterFailure centerId={""} authDone={0} /> */}
      {/* <StatsForRegisteredDevice centerId={""} registeredDevices={0} /> */}
      {/* <StatsForRegisteredUsers centerId={""} registeredUsers={0} /> */}
      {/* <SyncData /> */}
      {/* <RegistrationStatusTable /> */}
    </>
  );
}
