import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Card } from 'react-bootstrap';
import AdminService from '../../../../services/admin.service';
import DropzoneData from '../../../../components/Dropzone/Dropzone';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const download = require("downloadjs")

const InvigilatorData = () => {

    let [file, setFile] = useState<any>();
    let navigate = useNavigate();
    let params = useParams();
    const [stats, setStats] = useState<any>({})


    const getStats = async () => {
        await AdminService.getStatsByExamShift(params.examId, params.shiftId).then(res => {
            if (res.status === 200) {
                setStats(res.data.data)
            }
        }).catch(e => {
            console.log(e)
            toast.error("Invigilator not found")
        })
    }

    const handleUpload = async (files: any) => {
        let fd = new FormData();
        fd.append("uploadData", files[0]);
        toast.promise(
            AdminService.uploadInvigilatorSheetForPasswordCreation(params.examId, params.shiftId, fd).then(res => {
            if (res.status == 200) {
                toast.success("upload success");
                setTimeout(() => navigate("/"), 2000);
            }
            else {
                toast.error("Something went wrong");
            }
        }
        ), {
            success: "Upload Success",
            error: "Something went wrong",
            loading: "Uploading Please Wait",
        })
    }

    const handleDownload = async () => {
        await AdminService.createCSVForInvigilators(params.examId, params.shiftId).then((res: any) => {
            console.log(res);
            if (res.status === 200) {
                download(res.data, "invigilator.csv", "text/csv");
                toast.success("Donwload success");
            }
            else {
                toast.error("Something went wrong");
            }
        })
        // console.log("file downloaded Successfully")
    }


    useEffect(() => {
        getStats()
    }, [])


    return (
        <>
            <Card className="bio-card mt-4">
                <h4 className='m-5 text-muted fw-bold'>Final Step</h4>

               
                <hr />
                <Card.Body>
                <div className='me-2'>
                    <div className="text-danger">NOTE: Let this count be complete before you proceed</div>
                    <span className='xcn-text-12'>
                        Instructor Count:
                    </span>
                    <span className='xcn-text-12 fw-bold ms-1'>
                        {stats && stats.inv_count ? stats.inv_count : " - "}
                    </span>
                </div>
                <div className='me-2'>
                    <span className='xcn-text-12'>
                        Student Count:
                    </span>
                    <span className='xcn-text-12 fw-bold ms-1'>
                        {stats && stats.student_count ? stats.student_count : " - "}
                    </span>
                </div>

                    <div>

                        <div
                            className='d-flex flex-column align-items-center'
                        >
                            <button
                                className='btn btn-warning my-3'
                                onClick={handleDownload}
                            >
                                <FontAwesomeIcon icon={faDownload} className="mx-1" />
                                {"  "}Download Invigilator Data
                            </button>
                            <Card className="bio-card mt-4">
                                <Card.Body>
                                    <h6 className='text-muted fw-bold'>NOTE : </h6>
                                    <ul>
                                        <li>Download invigilator data</li>
                                        <li>Add respective email Ids in the email section</li>
                                        <li>Upload the updated sheet here</li>
                                    </ul>
                                </Card.Body>
                            </Card>

                            <br />
                            <DropzoneData
                                handleFileUpload={handleUpload}
                            />
                        </div>
                    </div>

                    <div className='d-flex flex-row justify-content-between align-items-center'>
                        <button
                            className='btn btn-success my-3 align-right'
                            onClick={(e: any) => navigate("/dashboard/invigilatorcentertable/" + params.examId + "/" + params.shiftId)}
                        >Back</button>
                    </div>
                </Card.Body>
            </Card>

        </>
    )
}

export default InvigilatorData