import React, { useState } from 'react'
import { Formik } from 'formik';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import clsx from 'clsx';
import toast from 'react-hot-toast';
import ExamService from '../../services/exam.service';
import Shift from '../../views/private/Dashboard/Exam/Shift';
import { faTimesCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface ICreateExam {
    show: boolean
    handleClose: () => void
    reload: any
}


const CreateExamModel = ({ show, handleClose, reload }: ICreateExam) => {

    const [examData, setExamData] = useState<any>();
    const [isActive, setIsActive] = useState<boolean>(false)


    const handleChangeValue = (e: any) => {
        setExamData({ ...examData, [e.target.name]: e.target.value });
    }

    const createExam = async (e: any) => {
        e.preventDefault();
        setIsActive(true)
        await ExamService.createExam(examData).then((res) => {
            if (res.status === 200) {
                toast.success("Exam created successfully");
                handleClose();
                setIsActive(false);
                reload();

            }
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <Modal.Body>
                <div className='d-flex justify-content-between align-items-center'>
                    <h5 className='text-primary fw-bold'>Create Exam</h5>
                    <div> <FontAwesomeIcon icon={faXmark} className='xcn-link-pointer' onClick={handleClose} /> </div>
                </div>
                <div className='fv-row mb-8'>
                    <Form.Label className='text-muted xcn-text-12'>Exam Name</Form.Label>
                    <Form.Control
                        className={clsx(
                            'form-control bg-transparent',
                        )}
                        type='text'
                        name='name'
                        autoComplete='off'
                        onChange={handleChangeValue}
                    />
                </div>
                <Button size="sm" className='btn btn-primary mt-3' onClick={createExam} disabled={isActive}>
                    Create
                </Button>
            </Modal.Body>
        </Modal>
    )
}

export default CreateExamModel