import { faFlag, faGear, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaBookOpen } from "react-icons/fa";
import paper from "../../images/paper.jpeg";
import AdminService from "../../services/admin.service";
import { CSRReportCard } from "../Card/CSRReportCard";
import { LiveExamCard, StatisticsCard, UsersCard } from "../Card/Statistics.card";
import { CSRReportNavbar } from "../Navbar/navbar";
import CsrReportTable from "../table/CsrReportTable";
import download from "downloadjs";
import toast from "react-hot-toast";
import { LoaderContext } from "../../Context";


const columns = ['invigilator', 'iris', 'finger Print', 'Facial']

export default function CSRReportComponent() {

  const [examNameData, setExamNameData] = useState<any>();
  const [selectExamData, setSelectExamData] = useState<any>()
  const [csrData, setCsrData] = useState<any>();
  const [shiftData, setShiftData] = useState<any>();
  const [verificationData, setVerificationData] = useState<any>();
  const [examId, setExamId] = useState<any>();
  const [shiftId, setShiftId] = useState<any>();
  const [countData, setCountData] = useState<any>();
  const {loading, setLoading}: any = useContext(LoaderContext);




  const onChangeValue = (e: any, name: string) => {
    setSelectExamData({ ...selectExamData, [name]: e.value })
  }

  const onChangeShiftValue = (e: any, name: string) => {
    setShiftData({ ...shiftData, [name]: e.value });
  };

  const getExamId = (id: string) => {
    setExamId(id)
  };

  const getShiftId = (id: string) => {
    setShiftId(id);
  };

  const examName = async () => {
    await AdminService.getAllExamName().then((res) => {
      if (res.status === 200) {
        setExamNameData(res.data.data.examName.map((data: any) => {
          return {
            value: data._id,
            label: data.name
          }
        }))
      }
    })
  }

  const getCSRReportData = async () => {
    await AdminService.getCSRReport(examId).then((res) => {
      if (res.status === 200) {
        setCsrData(res.data.data);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const getVerificationData = async () => {
    await AdminService.getVerificationData(examId, shiftId)
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data, 'data verify')
          setVerificationData(res.data.data.verifications);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getCountData = async () => {
    await AdminService.getCountData(examId, shiftId)
      .then((res) => {
        if (res.status === 200) {
          setCountData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShiftData = async () => {
    await AdminService.getShiftData(examId)
      .then((res) => {
        if (res.status === 200) {
          setShiftData(
            res.data.data.shifts.map((data: any) => {
              return {
                value: data._id,
                label: data.shiftName,
              };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const downloadVerifiedUsers = async () => {
    setLoading(true)
    await AdminService.downloadVerifiedUsers(examId, shiftId).then((res) => {
      if (res.status === 200) {
        download(res.data, "verifiedUsers.zip", "application/zip");
        toast.success("Donwload success");
        setLoading(false);
      }
    })
  }

  useEffect(() => {
    examName();
  }, [])

  useEffect(() => {
    if (examId) {
      getShiftData();
      getCSRReportData();
    }
  }, [examId]);


  useEffect(() => {
    if (examId && shiftId) {
      getCountData();
      getVerificationData();
    }
  }, [examId, shiftId])


  // const data = [
  //   {
  //     title: "abc",
  //     url: paper,
  //     subtitleOne: "AUTHENTICATED",
  //     subtitleOneContent: "20",
  //     subtitleTwo: "COUNT",
  //     subtitleTwoContent: "40",
  //     buttonContent: "Approve",
  //   },
  //   {
  //     title: "abc",
  //     url: paper,
  //     subtitleOne: "AUTHENTICATED",
  //     subtitleOneContent: "20",
  //     subtitleTwo: "COUNT",
  //     subtitleTwoContent: "40",
  //     buttonContent: "Approve",
  //   },
  //   {
  //     title: "abc",
  //     url: paper,
  //     subtitleOne: "AUTHENTICATED",
  //     subtitleOneContent: "20",
  //     subtitleTwo: "COUNT",
  //     subtitleTwoContent: "40",
  //     buttonContent: "Approve",
  //   },
  //   {
  //     title: "abc",
  //     url: paper,
  //     subtitleOne: "AUTHENTICATED",
  //     subtitleOneContent: "20",
  //     subtitleTwo: "COUNT",
  //     subtitleTwoContent: "40",
  //     buttonContent: "Approve",
  //   },
  // ];



  return (
    <div>
      <CSRReportNavbar
        data={examNameData}
        onSelectHandler={getCSRReportData}
        onChangeValue={onChangeValue}
        shiftData={shiftData}
        onChangeShiftValue={onChangeShiftValue}
        getExamId={getExamId}
        getShiftId={getShiftId}
      />
      <Row>
        <Col md={4} className="mt-4">
          <Button onClick={() => downloadVerifiedUsers()} size="sm" >Download Verified Users</Button>
        </Col>
      </Row>

      <h5 className="mt-5">
           Center Reports
          </h5>
      <Row>
        <Col md={3}>
          
          {csrData && csrData.CSRReport && csrData.CSRReport.length > 0
            ? csrData.CSRReport.map((csr: any, index: number) => {
              return (
                <CSRReportCard
                  title={index+1}
                  url={csr.CSRReport[0]}
                  centerId={csr.centerId}
                // buttonContent={data.buttonContent}
                />
              );
            })
            : "No Report Found"}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <UsersCard
            reactIcon={
              <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
            }
            header="Biometric"
            statsValue={
              countData?.biometricCount
                ? countData?.biometricCount
                : 0
            }
          />
        </Col>{" "}
        <Col md={4}>
          <UsersCard
            reactIcon={
              <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
            }
            header="Invigilators"
            statsValue={
              countData?.invigilatorCount
                ? countData?.invigilatorCount
                : 0
            }
          />
        </Col>
        <Col md={4}>
          <UsersCard
            reactIcon={
              <FontAwesomeIcon icon={faUser} className="fs-1 text-muted" />
            }
            header="Students"
            statsValue={
              countData?.usersCount
                ? countData?.usersCount
                : 0
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CsrReportTable columns={columns} data={verificationData} />
        </Col>
      </Row>
    </div>
  );
}


