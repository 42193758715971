import React from "react";

import { Routes, Outlet, Navigate, Route } from "react-router-dom";
import Auth from "../Auth";
import IRouter from "../interfaces/IRouter.interface";
import Dashboard from "../views/private/Dashboard/Dashboard.index";
import AdminLogin from "../views/public/Login/Login";
import dashboardRoutes from "./dashboard.routes";

function PrivateRouter({ children }: any) {
  const auth = Auth.checkAuth(); //return true or false everytime
  return auth ? <Dashboard /> : <Navigate to="/login" />; // if user is already logged in then redirect to dashboard else redirect to login page
}

export default function MainRouter() {
  return (
    <>
      <Routes>
        {/*path is dashboard the render privateRounter */}
        <Route path="dashboard" element={<PrivateRouter />}>
          {dashboardRoutes.map((data: IRouter) => {
            // data will follow poperty of IRouter
            return <Route path={data.path + "/*"} element={data.element} />;
          })}
        </Route>

        {/* if path is login go for login*/}
        <Route path="/login" element={<AdminLogin />} />

        {/* if path is * then go for /login using navigate component */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  );
}
