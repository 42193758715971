import React, { useState } from 'react'
import { Card } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import AdminService from '../../../../services/admin.service';

const CreateInvigilators = () => {

    let navigate = useNavigate();
    let params = useParams();
    let [invigilators, setInvigilators] = useState<any>(30);

    const handleCreateInvigilators = async () => {
        let obj = {
            // "centers": [
            //     "centerChoiceOne"
            // ],
            "requiredInvigilatorPerStudent": invigilators
        };
        await AdminService.createAutoInvigilatorForCenter(params.examId, params.shiftId, obj).then(res => {
            if (res.status == 200) {
                toast.success("Done");
            }
            else {
                toast.error("Something went wrong");
            }
        })
    }

    console.log(invigilators, "count")

    return (
        <>
            <Card className="bio-card mt-4">
                <Card.Body>
                    <h6 className="text-muted fw-bold">Step 3. Create Invigilator</h6>
                    <div className="d-flex align-items-center justify-content-around">
                        <div
                            className="text-primary fw-bold"
                        >Select number of Students per Invigilator</div>
                        <div>
                            <input className="form-control text-center"
                                style={{ width: "150px" }}
                                type="number"
                                value={invigilators}
                                onChange={(e: any) => setInvigilators(e.target.value)}
                            />
                        </div>
                        <button
                            className='btn btn-sm btn-secondary my-3'
                            onClick={handleCreateInvigilators}
                        >SET</button>
                    </div>


                    <div className='my-3 d-flex justify-content-around align-items-center'>
                        <div className='text-success'>
                            {"Updated Invigilator count : " + invigilators}
                        </div>
                    </div>


                    <div className='d-flex flex-row justify-content-between align-items-center'>
                        <button
                            className='btn btn-success my-3 align-right'
                            onClick={(e: any) => navigate("/dashboard/mapping/" + params.examId + "/" + params.shiftId)}
                        >Back</button>
                        <button
                            className='btn btn-success my-3 align-right'
                            onClick={(e: any) => navigate("/dashboard/invigilatorcentertable/" + params.examId + "/" + params.shiftId)}
                        >Next</button>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default CreateInvigilators