import React from 'react'
import { Modal } from 'react-bootstrap'

interface IUpdateInvigilatorCount {
    handleClose: any,
    show: any,
    updatedCount: any,
    setUpdatedCount: any,
    handleUpdate: any
}

const UpdateInvigilatorCountModal = ({ handleClose, show, updatedCount, setUpdatedCount, handleUpdate }: IUpdateInvigilatorCount) => {
    return (
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered'
            show={show}
            onHide={handleClose}
            backdrop={true}
            style={{ width: "130rem" }}
        >

            <div className="w-80 p-4">
                <p className="m-auto text-muted">Update Invigilator Count</p>
                <input className='form-control my-3'
                    id="updated_invigilator_count"
                    type="number"
                    value={updatedCount}
                    onChange={(e) => setUpdatedCount(parseInt(e.target.value))}
                />
                <button
                    className='btn btn-sm btn-primary'
                    onClick={() => { handleUpdate(updatedCount) }}
                >
                    Set</button>
                <button
                    className='btn btn-sm btn-secondary mx-3'
                    onClick={handleClose}
                >
                    Close</button>
            </div>
        </Modal>
    )
}

export default UpdateInvigilatorCountModal