import React from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import "./assets/card.css";
import MainRouter from "./routes/MainRouter";
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from "./components/Loaders/Loading";
import { LoaderContext } from "./Context";

const App: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <div className="App">
      <LoaderContext.Provider value={{ loading, setLoading }}>
        <Loading/>
        <BrowserRouter>
          <MainRouter />
        </BrowserRouter>
        <Toaster />
      </LoaderContext.Provider>
    </div>
  );
};

export default App;
