import { urls } from "../api/api.urls";
import makeRequest from "../api/make.request";
import makeUploadRequest from "../api/make.upload";

export default class StatsService {
    static async getSyncData(time: any, type: any) {
        return makeRequest(urls.stats.getSyncupData + "/" + time + "/" + type, "get");
    }

    static async getCentersWSRReportStatus(type: any) {
        return makeRequest(urls.stats.getCentersWSRReportStatus + "/" + type, "get");
    }

    static async getAuthUsersOnline() {
        return makeRequest(urls.stats.getAuthUsersOnline, "get");
    }

    static async totalCSRCount() {
        return makeRequest(urls.stats.totalCSRCount, "get");
    }

    static async totalExamCount() {
        return makeRequest(urls.stats.totalExamCount, "get");
    }

    static async totalLiveExamsCount() {
        return makeRequest(urls.stats.totalLiveExamsCount, "get");
    }

    static async getFailuresCount(examId: string, shiftId: string) {
        return makeRequest(urls.stats.getFailuresCount + "/" + examId + "/" + shiftId, "get");
    }

    static async getAuthenticationsDone(examId: string, shiftId: string) {
        return makeRequest(urls.stats.getAuthenticationsDone + "/" + examId + "/" + shiftId, "get");
    }
}