import { faExclamation, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface IResetPasswordModal {
    show: any,
    handleClose: any
}

const ResetPasswordModal = ({ show, handleClose }: IResetPasswordModal) => {
    console.log(show, "show")
    return (
        <Modal
            show={show ? true : false}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <div>
                        <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: "40px", textAlign: "center", color: "green", opacity: 0.5, marginBottom: "20px", marginLeft: "60px" }} />
                        <div>
                            <span className="fw-bold">Username:- </span><span className="text-muted">{show?.data?.username}</span>
                        </div>
                        <div>
                            <span className="fw-bold">Password:- </span><span className="text-muted">{show?.data?.password}</span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ResetPasswordModal