export const urls = {
  admin: {
    uploadCsvData: "/admin/uploadZipFile",
    getInternalId: "/admin/getUserDataInternalIds",
    mapInterIdsWithSheetIds: "/admin/mapInterIdsWithSheetIds",
    updateMappingData: "/admin/updateMappingData",
    createAutoInvigilatorForCenter: "/admin/createAutoInvigilatorForCenter",
    getMappings: "/admin/getMappings",
    getColumnData: "/admin/getColumnData",
    getAllUploadedFiles: "/admin/getAllUploadedFiles",
    getAssignedInviliatorCount: "/admin/getAssignedInviliatorCount",
    updateInvigilatorsCounts: "/admin/updateInvigilatorsCounts",
    confirmInvigilator: "/admin/confirmNoOfInvigilatorsForCenters",
    createCSVForInvigilators: "/admin/createCSVForInvigilators",
    uploadInvigilatorSheetForPasswordCreation:
      "/admin/uploadInvigilatorSheetForPasswordCreation",
    getAllExamName: "/admin/getAllExamName",
    getCSRReport: "/admin/getCSRReportById",
    getPastUploads: "/admin/getPastUploads",
    getExamShifts: "/admin/getExamShifts",
    getShiftData: "/admin/getExamShifts",
    getVerificationData: "/admin/getVerification",
    getCountData: "/stats/countInfo",

    getZones: "/stats/uniqueZones",
    getStates: "/stats/uniqueStates",
    getDistricts: "/stats/uniqueCities",
    getCenters: "/stats/uniqueCenters",
    getUsers: "/stats/getUsers",

    getAllClient: "/admin/getAllClient",
    createClient: "/admin/createClient",
    resetClientPassword: "/admin/resetPassword",
    deleteClient: "/admin/deleteClient",

    deleteShift: "/admin/deleteShift",
    checkIfFieldsExist: "/admin/checkFileMappingStep2",
    downloadVerifiedUsers: "/admin/verifiedZip",
    createStudent: "/admin/user/create",

    toggleOfflineExam: "/admin/exam/toggleOffline",
    getStatsByExamShift: "/admin/stats/get",
    getInstructorByExamShift :"/admin/invigilators/get"

  },

  exam: {
    createExam: "/exam/createExam",
    allExams: "/exam/getAllExam",
    getAllExamById: "/exam/getExamById",
    getAllEnumsForVerfication: "/exam/getAllEnumsForVerification",
    getExamSetting: "/exam/getExamSettings",
    updateExamSettings: "/exam/updateExamSettings",
    addexamShiifts: "/exam/addShiftToExam",
    toggleExamIsLive: "/exam/toggleExamIsLive",
    updateShifts: "/exam/updateShifts",
    deleteExam: "/exam/deleteExam",
  },

  profile: {
    getUserRoles: "/profile/getUserRoles",
    getAllQuestiontypes: "/profile/getAllQuestiontypes",
    getAllSubjects: "/profile/getAllSubjects",
  },
  public: {
    userLogin: "/admin/login",
    getLanguage: "/public/getAllLanguage",
  },
  users: {
    getAllUsers: "/users/getAllUsers",
    changeUserRole: "/users/updateUserRole",
    blockUnblockUser: "/users/blockUnblockUser",
    userUpdate: "/users/userUpdate",
    register: "/users/register",
  },
  request: {
    getLogs: "/request/getLogs",
  },
  subjects: {
    insert: "/subject/insert",
    update: "/subject/update",
  },
  upload: {
    uploadMultimedia: "/upload/uploadMultimedia",
    uploadQuestion: "/upload/uploadQuestion",
  },
  qp_generation: {
    checkQuestionAvailability: "/qpgeneration/checkQuestionAvailability",
  },

  stats: {
    getSyncupData: "/stats/getSyncupData",
    getCentersWSRReportStatus: "/stats/getCentersWSRReportStatus",
    getAuthUsersOnline: "/stats/getAuthUsersOnline",
    getFailuresCount: "/stats/getFailuresCount",
    getAuthenticationsDone: "/stats/getAuthenticationsDone",
    totalCSRCount: "/stats/totalCSRCount",
    totalExamCount: "/stats/totalExamCount",
    totalLiveExamsCount: "/stats/totalLiveExamsCount",
  },
};