import { faEye, faXmarkCircle } from '@fortawesome/free-regular-svg-icons'
import { faDeleteLeft, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { formatDate } from '../../../../components/helper/Date'
import ToggleSwitch from '../../../../components/toggle/ToggleSwitch'
import ExamService from '../../../../services/exam.service'
import ConfirmModal from '../../../../components/Modal/Confirm.modal'

interface IExamCard {
    data: any
    reload: any
}

const ExamCard = ({ data, reload }: IExamCard) => {

    const [examCheck, setExamCheck] = useState<boolean>(false);
    const navigate = useNavigate();

    const [showConfirmExamDelete, setShowConfirmExamDelete] = useState<boolean>(false);


    const toggleExam = () => {
        ExamService.toggleExamIsLive(data._id).then((res) => {
            if (res.status === 200) {
                reload()
            }
        }).catch(e => {
            console.error(e);
            toast.error("Unable to toggle exam status");
        })
    }

    const deleteExam = () => {
        ExamService.deleteExam(data._id).then((res) => {
            if (res.status === 200) {
                reload()
                setShowConfirmExamDelete(false)
            }
        }).catch(e => {
            console.error(e);
            toast.error("Unable to delete exam");
        })
    }

    return (
        <>
            {data &&
                <Card className="bio-card mt-3">
                    <Card.Body>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className="xcn-text-14 xcn-fw-600 text-muted d-flex align-items-center">Exam Name:
                                <span className='ms-1 xcn-fw-500 text-black'>{data.name}</span>
                                <span className="d-flex align-items-center">
                                    <Badge className="ms-2" bg={data.isLive ? "success" : "danger"}>{data.isLive ? "Live" : "Not Live"}</Badge> <FontAwesomeIcon icon={faRefresh} onClick={toggleExam} className="ms-2 text-warning" />
                                </span>
                            </div>
                            <div>
                                <span
                                    onClick={() => { setShowConfirmExamDelete(true) }}
                                    className="xcn-text-12 xcn-fw-600 text-danger m-2 xcn-link-pointer"
                                >
                                    Delete <FontAwesomeIcon icon={faTrash} />
                                </span>
                                <span
                                    onClick={() => { navigate('/dashboard/exam/' + data._id) }}
                                    className="xcn-text-12 m-2 xcn-fw-600 text-info xcn-link-pointer"
                                >
                                    View <FontAwesomeIcon icon={faEye} />
                                </span>

                            </div>
                        </div>
                        <div>
                            <span className="xcn-text-14 xcn-fw-600 text-muted">No. of shifts: </span><span className="xcn-text-14 xcn-fw-600 text-black">{data.shifts.length}</span>
                        </div>
                        <hr className="text-muted" />
                        <Row>
                            <Col>
                                <div className="xcn-text-10 ">
                                    <span className="xcn-fw-500 text-muted">Created At:</span><span className="ms-2">{formatDate(data.createdAt)}</span><br />
                                    <span className="xcn-fw-500 text-muted">Last Updated At:</span><span className="ms-2">{formatDate(data.updatedAt)}</span><br />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }

            <ConfirmModal
                titleText={"Confirm Delete Exam"}
                bodyText={"Are you sure you want to delete this exam?"}
                show={showConfirmExamDelete}
                onHide={() => { setShowConfirmExamDelete(false) }}
                button={[
                    {
                        label: <><FontAwesomeIcon icon={faXmarkCircle}/> Cancel</>,
                        action: () => { setShowConfirmExamDelete(false) },
                        variant: "secondary"
                    },
                    {
                        label: <><FontAwesomeIcon icon={faTrash}/> Delete</>,
                        action: () => { deleteExam() },
                        variant: "outline-danger"
                    }
                ]}

            />
        </>
    )
}

export default ExamCard