import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../../../components/Sidebar/Sidebar";
import dashboardRoutes, { baseUrl } from "../../../routes/dashboard.routes";
import "./index.css";

const Dashboard = () => {
  return (
    <div className="d-flex justify-content-between">
      <SideBar
        menuData={dashboardRoutes}
        panelName="Admin"
        baseUrl={baseUrl}
      />
      <div className="xrg-outlet-main">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
