import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface IButton {
    label: any
    action: any
    variant: any
}

interface IConfirmModal {
    titleText: any;
    bodyText: any;
    show: boolean;
    onHide: any;
    button: IButton[]
}

export default function ConfirmModal(props: IConfirmModal) {
    return (
        <>
            <Modal size="sm" show={props.show} onHide={props.onHide}>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        {props.titleText}
                    </div>
                    <div className="text-secondary xcn-text-12 mt-2">
                        {props.bodyText}
                    </div>
                    <div className='d-flex justify-content-end align-items-center mt-2'>
                        {props.button.map((btn, index) => {
                            return (
                                <>
                                    <Button variant={btn.variant} size='sm' className="xcn-text-12 xcn-fw-600 m-1" onClick={btn.action}>
                                        {btn.label}
                                    </Button>
                                </>
                            )
                        })}
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}